<template>
  <div class="comp-collective-member">
    <img  v-if="member.image"
          class="member-image"
          :src="resizeImage(member.image, '500x0')"
          :alt="member.forename + ' ' + member.familyname">

    <p    v-if="member.function"
          class="member-function headline-keyword">
      {{ member.function }}
    </p>

    <div  class="member-name headline-ambient">
      <span  v-if="member.title_before_name">
        {{ member.title_before_name }}
      </span>
      <span v-if="member.forename">
        {{ member.forename }}
      </span>
      <span v-if="member.familyname">
        {{ member.familyname }}<span v-if="member.title_after_name">,</span>
      </span>
      <span  v-if="member.title_after_name">
        {{ member.title_after_name }}
      </span>
    </div>

    <div class="member-links">
      <a    v-if="member.domain_url.url"
            class="member-domain"
            target="_blank"
            :href="member.domain_url.url">
        {{ member.domain_visible }}
      </a>

      <a    v-if="member.mail.url"
            :href="member.mail.url"
            class="member-mail">
        {{ member.mail.url }}
      </a>

      <a    v-if="member.phone"
            class="member-phone"
            :href="'tel:' + member.phone">
        {{ member.phone }}
      </a>
    </div>

    <p    v-if="member.description"
          class="member-description copytext">
      {{ member.description }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    member: Object,
  },
  methods: {
    resizeImage(image, options) {
      var imageService = '//img2.storyblok.com/'
      var path = image.replace('//a.storyblok.com', '')
      return imageService + options + path
    },
  },
  mounted() {
    console.log(this.member)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '../assets/css/typography.scss';

  .comp-collective-member {
    color: var(--color-ink-05) !important;
    // display: inline-block;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    height: 100%;
    break-inside: avoid;
    page-break-inside: avoid;
    box-sizing: border-box;
    transform: translateX(0);
    text-decoration: none;
    color: inherit;
    padding-bottom: var(--spacer-pixel-05);

    @media screen and (min-width: 1000px) {
      max-width: unset;
    }

    .member-image {
      width: 100%;
      margin-bottom: var(--spacer-pixel-02);
    }

    .member-name {
        margin: var(--spacer-pixel-02) 0 var(--spacer-pixel-02);
    }

    a {
      display: block;
    }

    .member-links {
      margin-bottom: var(--spacer-pixel-02);

      *:not(:last-of-type) {
        margin-bottom: calc(var(--spacer-pixel-01) / 2);
      }

      .member-domain,
      .member-mail,
      .member-phone {
        @extend .copytext;
        color: inherit;
        text-align: center;
      }
    }
  }
</style>
