<template>
  <div>
    <transition name="fade">
      <div v-show="this.loaded" class="holder-comp">
        <div class="grid-collective-members">
          <CollectiveMember class=""
                            v-for="member in this.story.content.body"
                            :member="member"/>
        </div>
      </div>
    </transition>
    <Footer :class="{ 'footer-padding-top': !this.loaded }" />
  </div>
</template>

<script>
// @ is an alias to /src
import CollectiveMember from '@/components/CollectiveMember.vue'

// 1. Require the Storyblok client
import StoryblokClient from 'storyblok-js-client'

// 2. Set your token - you will be able to create a new space later.
const token = 'cBmyIDJ7tqjDjQuQXoB3gQtt';

// 3. Initialize the client with the preview token so we can access our API easily
// from your space dashboard at https://app.storyblok.com
let storyapi = new StoryblokClient({
  accessToken: token
})  

export default {
  data () {
    return {
      story: {
        content: {
          body: []
        }
      },
      loaded: false,
    }
  },
  components: {
    CollectiveMember,
  },
  metaInfo: {
    title: 'Kollektivmitglieder unserer Praxisgemeinschaft | PRAXIS KOLLEKTIV WOLLZEILE',
    meta: [
      {name: 'description', content: `Die Mitglieder des PRAXIS KOLLEKTIV WOLLZEILE arbeiten in unterschiedlichen Disziplinen der Psychosozialen Berufe.`},
      // Schema.org markup for Google+
      {itemprop: 'description', content: `Die Mitglieder des PRAXIS KOLLEKTIV WOLLZEILE arbeiten in unterschiedlichen Disziplinen der Psychosozialen Berufe.`},
      {name: 'robots', content: `index, follow`}
    ],
  },
  methods: {
    getStory(slug, version) {
      storyapi.get('cdn/stories/' + slug, {
        version: version
      })
      .then((response) => {
        this.story = response.data.story

        this.loaded = true;
  
        // let el = document.querySelector('.grid-collective-members');
        // el.setAttribute('data-storyblok', 'true');
        // document.dispatchEvent(new Event('custom-render-trigger'))
      })
      .catch((error) => {
        console.log(error);
      })
    },
  },
  created () {
    // 4. Initialize the Storyblok Client Bridge to allow us to subscribe to events
    // from the editor itself.
    window.storyblok.init({
      accessToken: token
    })
    window.storyblok.on('change', () => {
      // this will indicate to load the home story, exchange that with the full slug of your content
     // either it is the page URL or hardcoded as in the example below
      this.getStory('das-kollektiv', 'draft')
    })
    window.storyblok.pingEditor(() => {
      if (window.storyblok.isInEditor()) {
        this.getStory('das-kollektiv', 'draft')
      } else {
        this.getStory('das-kollektiv', 'published')
      }
    })
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>

// .view,
// .preloader-height {
//   min-height: 100vh;
// }

.footer-padding-top {
  padding-top: 100vh;
}

.grid-collective-members {
  column-count: 1;
  column-gap: calc(var(--spacer-responsive-03)*1);
  transform: translateZ(-1px);

  @media screen and (min-width: 1000px) {
    column-count: unset;
    column-width: calc(300px + 2.5vmin);
  }

  // @media screen and (min-width: 1200px) {
  //   column-count: 3;
  // }

  // @media screen and (min-width: 1200px) {
  //   column-count: 3;
  // }
}

</style>